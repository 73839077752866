import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../Stores/store";
import { toast } from "react-toastify";
import { Button, Dropdown, Header, Icon, Input, Segment } from "semantic-ui-react";
import agent from "../../../api/agent";
import SchoolsList from "./schoolsList";
import LoadingComponent from "../../Layout/loadingComponent";
import { PaginatedResult, PagingParams } from "../../../models/common/pagination";
import { gridPageSize } from "../../../models/constants";
import ConfirmDeleteSchool from "./confirmDeleteSchool";
import { SchoolLicenseSummaryDto } from "../../../models/schools/schoolLicenseSummaryDto";
import { SortBy } from "../../../models/enums";

export default function ManageAllSchools() {
  const { t } = useTranslation("common");
  const { userStore } = useStore();
  const navigate = useNavigate();
  const [schools, setSchools] = useState<SchoolLicenseSummaryDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [selectedSchool, setSelectedSchool] = useState<SchoolLicenseSummaryDto | null>(null);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);

  const [pagingParams, setPagingParams] = useState<PagingParams>({
    pageNumber: 1,
    pageSize: gridPageSize,
    filter: "",
    orderBy: SortBy.NameAsc,
  });

  const handleError = (error: any) => {
    toast.error(t("Common.GenericErrorMessage"));
  };

  const loadSchools = (params: PagingParams) => {
    agent.Schools.list(params)
      .then((response) => {
        let result = response as unknown as PaginatedResult<SchoolLicenseSummaryDto[]>;
        setTotalItemCount(result.pagination.totalItems);
        setSchools(result.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!userStore.isLoggedIn || !userStore.isSystemAdmin) navigate("/");
    loadSchools(pagingParams);
  }, []);

  const searchSchools = (filter: string) => {
    let params: PagingParams = { ...pagingParams, filter: filter, pageNumber: 1 };
    setPagingParams(params);
    loadSchools(params);
  };

  const content = (
    <Segment className="t4-mainmenu">
      <div className="t4-header-collection">
        <Header as="h1" className="t4-header-left" textAlign="left">
          Manage Schools
        </Header>
      </div>
      <div className="t4-mng-btns">
        <Button
          icon="add"
          content={"Add New"}
          onClick={() => {
            navigate("/dashboard/newschool");
          }}
        ></Button>
        <Button
          icon="edit"
          content={"Edit"}
          disabled={selectedSchool == null}
          onClick={() => {
            navigate(`/dashboard/editschool/${selectedSchool?.code}`);
          }}
        ></Button>
        <Button
          icon="delete"
          content={"Delete"}
          disabled={selectedSchool == null}
          onClick={() => {
            setShowDeleteConfirmation(true);
          }}
        ></Button>
        <Input
          onChange={(e) => searchSchools(e.target.value)}
          value={pagingParams.filter}
          autoComplete="new-password"
          placeholder={"Search..."}
          icon={
            <Icon
              name="delete"
              link
              onClick={() => {
                searchSchools("");
              }}
            />
          }
        />
      </div>
      {schools && schools.length > 0 && (
        <>
          <span className="t4-sortby">
            <Icon name="sort amount down" />
            {"Sort by : "}
          </span>
          <Dropdown
            className="t4-sorted"
            value={pagingParams.orderBy as number}
            options={[
              { text: "Name Ascending", value: SortBy.NameAsc },
              { text: "Name Descending", value: SortBy.NameDsc },
              { text: "Contract Start Date Ascending", value: SortBy.ContractStartDateAsc },
              { text: "Contract Start Date Descending", value: SortBy.ContractStartDateDsc },
              { text: "Contract End Date Ascending", value: SortBy.ContractEndDateAsc },
              { text: "Contract End Date Descending", value: SortBy.ContractEndDateDsc },
            ]}
            onChange={(e, d) => {
              setLoading(true);
              let params = {
                ...pagingParams,
                pageNumber: 1,
                pageSize: gridPageSize,
                orderBy: d.value as SortBy,
              };
              setPagingParams(params);
              loadSchools(params);
            }}
          />
          <SchoolsList
            schools={schools}
            onSchoolSelected={(school) => setSelectedSchool(school)}
            currentPage={pagingParams.pageNumber}
            totalItemCount={totalItemCount}
            onPageChange={(pageNumber) => {
              let param = { ...pagingParams, pageNumber: pageNumber };
              setPagingParams(param);
              loadSchools(param);
            }}
          />
        </>
      )}
    </Segment>
  );

  return (
    <>
      {showDeleteConfirmation && (
        <ConfirmDeleteSchool
          school={selectedSchool}
          onClose={(refresh: boolean) => {
            setShowDeleteConfirmation(false);
            if (refresh) {
              setSelectedSchool(null);
              let params = {
                filter: "",
                pageNumber: 1,
                pageSize: gridPageSize,
                orderBy: pagingParams.orderBy,
              };
              setPagingParams(params);
              loadSchools(params);
            }
          }}
        />
      )}
      {loading && <LoadingComponent content={""} />}
      {!loading && content}
    </>
  );
}
