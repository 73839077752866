import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { Formik } from "formik";
import CustomTextInput from "../../../Common/customTextInput";
import CustomCheckboxInput from "../../../Common/customCheckboxInput";
import CustomDateInput from "../../../Common/customDateInput";
import agent from "../../../api/agent";
import { toast } from "react-toastify";
import NewSchoolDto from "../../../models/schools/newSchoolDto";
import { error } from "console";

export default function AddEditSchoolLicense() {
  let params = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [school, setSchool] = useState<NewSchoolDto>({
    id: 0,
    contactEmail: "",
    contactFirstName: "",
    contactLastName: "",
    contactPhone: "",
    includeBenchmarkData: false,
    licenseEndDate: null,
    licenseStartDate: null,
    name: "",
    numberOfSurveys: null,
    previousCustomer: false,
  });

  useEffect(() => {
    //intialize school contact

    if (params.schoolCode) {
      agent.Schools.edit(params.schoolCode)
        .then((result) => {
          setSchool(result);
        })
        .catch((error) => toast.error("Failed to load school details"));
    } else {
      let s = { ...school, name: "", contactFirstName: "", contactLastName: "" };
      setSchool(s);
    }
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    numberOfSurveys: Yup.number().typeError("Invalid numeric value").min(1, "A school must have at least one  survey assigned  to it.").required("Required"),
    licenseStartDate: Yup.date().typeError("Invalid date value").required("Required"),
    licenseEndDate: Yup.date().typeError("Invalid date value").required("Required").min(Yup.ref("licenseStartDate"), "End date can't be before Start date"),
    contactFirstName: Yup.string().required("Required"),
    contactLastName: Yup.string().required("Required"),
    contactPhone: Yup.string().required("Required"),
    contactEmail: Yup.string().required("Required").email("Invalid email format"),
  });

  const submitForm = (values: NewSchoolDto) => {
    if (params.schoolCode) updateSchool(values);
    else addNewSchool(values);
  };

  const addNewSchool = (values: NewSchoolDto) => {
    setIsSaving(true);
    agent.Schools.add(values)
      .then((result) => {
        toast.success("School added successfully");
        navigate(`/dashboard/manageschools`);
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409) toast.error("A school with the same name already exists !");
        else toast.error("Failed to add school !");
      })
      .finally(() => setIsSaving(false));
  };

  const updateSchool = (values: NewSchoolDto) => {
    setIsSaving(true);
    agent.Schools.update(values)
      .then((result) => {
        toast.success("School updated successfully");
        navigate(`/dashboard/manageschools`);
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 409) toast.error("A school with the same name already exists !");
        else toast.error("Failed to update school !");
      })
      .finally(() => setIsSaving(false));
  };

  const content = (
    <>
      <Segment className="t4-mainmenu">
        <div className="t4-headers">
          <Header as="h2" className="t4-header-left" textAlign="left">
            School License
          </Header>
        </div>
        <Formik validationSchema={validationSchema} enableReinitialize initialValues={school} onSubmit={(values) => submitForm(values)}>
          {({ handleSubmit, values }) => (
            <Form className="t4-add-edit" onSubmit={handleSubmit}>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput labeltext={"Full School Name *"} placeholder={"Full School Name"} name="name" disabled={false} />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput labeltext={"Contact First Name *"} placeholder={"Contact First Name"} name="contactFirstName" disabled={false} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput labeltext={"Contact Last Name *"} placeholder={"Contact Last Name"} name="contactLastName" disabled={false} />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput labeltext={"Contact Email *"} placeholder={"Contact Email"} name="contactEmail" disabled={false} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomTextInput labeltext={"Contact Phone *"} placeholder={"Contact Phone"} name="contactPhone" disabled={false} />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTextInput labeltext={"Number of surveys per year *"} placeholder={"Number of surveys per year"} name="numberOfSurveys" disabled={false} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomDateInput label={"Contract start date *"} placeholder={"Contract start date"} name="licenseStartDate" />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomDateInput label={"Contract end date *"} placeholder={"Contract end date"} name="licenseEndDate" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomCheckboxInput label="Include benchmark data" type="checkbox" disabled={false} name="includeBenchmarkData" />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomCheckboxInput label="Previous customer" type="checkbox" disabled={false} name="previousCustomer" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="t4-form-bot-btns">
                <Button floated="right" type="submit" color="blue" content="Save" loading={isSaving} className="t4-btn-active" />
                <Button
                  className="t4-btn-non"
                  floated="right"
                  disabled={isSaving}
                  onClick={() => {
                    navigate(`/dashboard/manageschools`);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );

  return <>{content}</>;
}
